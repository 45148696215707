/* You can add global styles to this file, and also import other style files */
html,
body {
  font-size: 16px;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  position: fixed;
}

body.light-theme {
  background-color: #ffffff;
}

body.dark-theme {
  background-color: var(--bluegray-900);
}
